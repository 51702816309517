import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Incoterms",
  "description": "International rules formulated for international trade business transactions, determining the responsibilities and expenses of buyers and sellers.",
  "author": "Ally Cheng",
  "categories": ["shipping-packaging"],
  "date": "2022-08-08T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-are-incoterms"
    }}>{`What are INCOTERMS?`}</h2>
    <p>{`"International Rules for the Interpretation of Trade Terms" (abbreviated to INCOTERMS) is the basic international rules for international trade formulated by the International Chamber of Commerce. Incoterms are provisions that define the responsibilities of buyers and sellers in the operation of international trading system, and are the authoritative rules for determining how costs and risks are allocated to the parties.`}</p>
    <h2 {...{
      "id": "what-are-international-trade-terms"
    }}>{`What are international trade terms?`}</h2>
    <p>{`There are 11 types of international trade terms in total. The 11 types are further divided into two categories: `}</p>
    <ul>
      <li parentName="ul">{`The first category is applicable to `}<strong parentName="li">{`any mode of transportation`}</strong>{` where the `}<strong parentName="li">{`goods are not handed over on board or alongside the vessel.`}</strong>{`                                       `}
        <ul parentName="li">
          <li parentName="ul">{`EXW `}</li>
          <li parentName="ul">{`FCA `}</li>
          <li parentName="ul">{`CPT `}</li>
          <li parentName="ul">{`CIP `}</li>
          <li parentName="ul">{`DAP `}</li>
          <li parentName="ul">{`DPU `}</li>
          <li parentName="ul">{`DDP `}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`The second category is applicable for `}<strong parentName="li">{`sea transportation`}</strong>{` and designed for `}<strong parentName="li">{`goods placed on board, alongside the vessel or in port.`}</strong>{`                         `}
        <ul parentName="li">
          <li parentName="ul">{`FAS `}</li>
          <li parentName="ul">{`FOB `}</li>
          <li parentName="ul">{`CFR `}</li>
          <li parentName="ul">{`CIF `}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "what-are-the-costs-involved-and-who-are-responsible-for-the-trade"
    }}>{`What are the costs involved and who are responsible for the trade?`}</h2>
    <p>{`The costs involved and the responsible parties are different for each Incoterm. `}</p>
    <h3 {...{
      "id": "1-ex-worksexw"
    }}>{`1. Ex Works（EXW)`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In terms of transportation the seller bears "no" costs.                    `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The buyer bears `}<strong parentName="p">{`insurance premium + trucking charges or loading fees + customs declaration fees + terminal handling charges + ocean freight + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`.
The cost of seller is controllable and the buyer will cover all the expenses. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In terms of the division of responsibilities, the seller is only responsible for delivering the goods to the buyer or the buyer's carrier.                  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<strong parentName="p">{`buyer takes all the responsibilities`}</strong>{` after delivery of the goods by the seller.
The seller's responsibility is controllable, while the buyer shoulders a great responsibility.                  `}</p>
      </li>
    </ul>
    <p>{`To sum up, the seller only needs to produce the goods and deliver them to the buyer or buyer's carrier at their own factory! All the expenses and responsibilities shall be borne by the buyer. `}</p>
    <h3 {...{
      "id": "2-free-carrierfca"
    }}>{`2. Free Carrier（FCA）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers:`}</p>
    <p>{`Seller delivers the goods either at its own factory or at other locations, such as the warehouse of the buyer's carrier.  `}</p>
    <p>{`If the goods are `}<strong parentName="p">{`delivered at the buyer's factory`}</strong>{`,                            `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In terms of transportation, the seller bears "no" cost.               `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The buyer bears `}<strong parentName="p">{`insurance premium + trucking charges or loading fees+ customs declaration fees + terminal handling charges + ocean freight + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`.              `}</p>
        <p parentName="li">{`The seller's responsibility is controllable, while the buyer shoulders a great responsibility. `}</p>
      </li>
    </ul>
    <p>{`If the goods are delivered at the `}<strong parentName="p">{`warehouse of the buyer's carrier`}</strong>{`,                                                `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Seller needs to pay the `}<strong parentName="p">{`inland charges + warehouse fees`}</strong>{` for the delivery from factory to warehouse.             `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The buyer bears `}<strong parentName="p">{`insurance premium + customs declaration fees + terminal handling charges + ocean freight + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`.                              `}</p>
        <p parentName="li">{`The cost of the seller is controllable and the buyer will cover all the expenses after receiving the goods. `}</p>
      </li>
    </ul>
    <p>{`To sum up, the seller only needs to produce the goods and deliver them at their own factory or deliver them to the buyer or the buyer's carrier! The buyer bears most of the expenses and responsibilities. `}</p>
    <h3 {...{
      "id": "3-carriage-paid-tocpt"
    }}>{`3. Carriage paid To（CPT）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <p>{`The seller's costs and responsibilities under this clause are similar to those in the case of `}<a parentName="p" {...{
        "href": "/articles/Incoterms/#2-free-carrierfca"
      }}>{`FCA`}</a>{`, `}<em parentName="p">{`except`}</em>{` for the `}<strong parentName="p">{`final place of delivery`}</strong>{`. The final place of delivery determines the costs and responsibilities to be borne by the seller. Once the goods are delivered to the buyer or buyer's carrier, the responsibility then transfers. `}</p>
    <p>{`To sum up, the seller and the buyer agree on the final delivery place of the goods, then the freight and obligations that the seller needs to bear will end there, and the rest is the buyer's. `}</p>
    <h3 {...{
      "id": "4-carriage-and-insurance-paid-tocip"
    }}>{`4. Carriage and Insurance Paid To（CIP）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers:`}</p>
    <ul>
      <li parentName="ul">{`The seller's costs and responsibilities under this clause term are similar to those in the case of `}<a parentName="li" {...{
          "href": "/articles/Incoterms/#2-free-carrierfca"
        }}>{`FCA`}</a>{`, `}<em parentName="li">{`except`}</em>{` that the seller needs to pay the `}<strong parentName="li">{`insurance premium`}</strong>{` in addition to the freight of delivery to the destination. `}</li>
      <li parentName="ul">{`All the risks and expenses after delivery shall be borne by the buyer. `}</li>
    </ul>
    <p>{`To sum up, the seller and the buyer agree on the final delivery place of the goods, then the freight plus insurance premium and responsibilities that the seller needs to bear will end there, and the rest is the buyer's. `}</p>
    <h3 {...{
      "id": "5-delivered-at-placedap"
    }}>{`5. Delivered at Place（DAP）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">{`The target place of delivery is very general, and it depends on the final delivery place negotiated by the seller and buyer.  `}</li>
      <li parentName="ul">{`If the place of delivery is at the buyer's factory, the buyer's costs and responsibilities can refer to the terms of `}<a parentName="li" {...{
          "href": "/articles/Incoterms/#1-ex-worksexw"
        }}>{`EXW`}</a>{` or `}<a parentName="li" {...{
          "href": "/articles/Incoterms/#2-free-carrierfca"
        }}>{`FCA`}</a>{`, and the rest is the buyer's. `}</li>
      <li parentName="ul">{`If the place of delivery is at the port of export, the buyer's costs and responsibilities can refer to the terms of `}<a parentName="li" {...{
          "href": "/articles/Incoterms/#9-free-on-boardfob"
        }}>{`FOB`}</a>{`, and the rest is the buyer's. `}</li>
      <li parentName="ul">{`If the place of delivery is at the buyer's warehouse, the buyer's costs and responsibilities can refer to the terms of `}<a parentName="li" {...{
          "href": "/articles/Incoterms/#7-delivered-duty-paidddp"
        }}>{`DDP`}</a>{`, and the rest is the buyer's. `}</li>
    </ul>
    <h3 {...{
      "id": "6-delivered-at-place-unloadedddu"
    }}>{`6. Delivered at Place Unloaded（DDU）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers:`}</p>
    <ul>
      <li parentName="ul">{`In terms of transportation, the seller bears `}<strong parentName="li">{`trucking charges or loading fees + customs declaration fees + terminal handling charges + insurance premium + ocean freight + customs clearance fees + delivery fees (including additional costs that may be incurred)`}</strong>{`. `}</li>
      <li parentName="ul">{`The buyer bears `}<strong parentName="li">{`customs duties`}</strong>{`.`}</li>
    </ul>
    <p>{`Under the clause of DDU, except for the customs duties, all the costs and responsibilities are borne by the seller.
In total, the seller bears the most expenses and the greatest responsibility. `}</p>
    <h3 {...{
      "id": "7-delivered-duty-paidddp"
    }}>{`7. Delivered Duty Paid（DDP）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">{`In terms of transportation, the seller bears `}<strong parentName="li">{`trucking charges or loading fees + customs declaration fees + terminal handling charges + insurance premium + ocean freight + customs clearance fees + delivery fees" (including additional costs that may be incurred)`}</strong>{`. `}</li>
      <li parentName="ul">{`The buyer bears "no” costs. `}</li>
    </ul>
    <p>{`Under the clause of DDP, all the expenses and responsibilities shall be borne by the seller.
To sum up, the seller bears the most expenses and the greatest responsibility. `}</p>
    <h3 {...{
      "id": "8-free-alongside-shipfas"
    }}>{`8. Free Alongside Ship（FAS）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In terms of transportation, the seller bears the costs of `}<strong parentName="p">{`trucking charges or loading fees + customs declaration fees + terminal handling charges`}</strong>{` from the factory to the port where the ship designated by the buyer is located. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The buyer bears `}<strong parentName="p">{`insurance premium + ocean freight + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In terms of division of responsibility, the seller's responsibility ends when the goods are delivered to the port designated by the buyer. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The buyer takes on the rest of the responsibilities. `}</p>
      </li>
    </ul>
    <p>{`To sum up, the responsibilities and expenses of the seller end upon the goods are delivered to the port where the ship designated by the buyer is located, and the remaining responsibilities and costs are passed on to the buyer. Generally, the buyer bears more cost and responsibilities than the seller. `}</p>
    <h3 {...{
      "id": "9-free-on-boardfob"
    }}>{`9. Free on Board（FOB）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">{`In terms of transportation, the seller bears the costs of `}<strong parentName="li">{`trucking charges or loading fees + customs declaration fees + terminal handling charges`}</strong>{`. `}</li>
      <li parentName="ul">{`The buyer bears `}<strong parentName="li">{`insurance premium + ocean freight + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`. `}</li>
      <li parentName="ul">{`The seller is responsible for delivering the goods to the ship designated by the buyer or the buyer's carrier. After the seller delivers the goods to the ship, the buyer takes all the remaining responsibilities. `}</li>
    </ul>
    <p>{`To sum up, the obligations and expenses of the seller end upon the goods are delivered to the ship, and the remaining responsibilities and costs are passed on to the buyer. `}</p>
    <h3 {...{
      "id": "10-cost-and-freightcfr"
    }}>{`10. Cost and Freight（CFR）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">{`In terms of transportation, the seller bears the costs of `}<strong parentName="li">{`trucking charges or loading fees + customs declaration fees + terminal handling charges + ocean freight`}</strong>{`. `}</li>
      <li parentName="ul">{`The buyer bears `}<strong parentName="li">{`insurance premium + customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred)`}</strong>{`.`}</li>
    </ul>
    <p>{`The seller and the buyer bear the costs and responsibilities almost equally, and additional expenses may be incurred by both parties.
The bottom line is that the seller delivers the goods to the port to be received by the buyer, then responsibilities and costs are passed on to the buyer. `}</p>
    <h3 {...{
      "id": "11-costinsurance-and-freightcif"
    }}>{`11. Cost,Insurance and Freight（CIF）`}</h3>
    <p>{`Costs and responsibilities of buyers and sellers: `}</p>
    <ul>
      <li parentName="ul">{`In terms of transportation, the seller bears the cost of `}<strong parentName="li">{`trucking charges or loading fees + customs declaration fees + terminal handling charges + ocean freight + insurance premium`}</strong>{`. `}</li>
      <li parentName="ul">{`The buyer bears `}<strong parentName="li">{`customs clearance fees + customs duties + delivery fees (including additional costs that may be incurred`}</strong>{`. `}</li>
    </ul>
    <p>{`CIF is almost the same as `}<a parentName="p" {...{
        "href": "/articles/Incoterms/#10-cost-and-freightcfr"
      }}>{`CFR`}</a>{`, except that the insurance premium is borne by the seller under the clause of CIF.
To sum up, the seller is responsible for delivering the goods to the port agreed with the buyer and pay the insurance premium, then the remaining responsibilities and costs are transferred to the buyer. `}</p>
    <p>{`To agree on an Incoterm, the seller should first explain the trade terms of the price when quoting to the customer. If no Incoterm was agreed on, the trade can't happen. Incoterms should be agreed first before the trade happens since there should be a responsible party to pay the freight and taxes, also to take risks throughout each process. `}</p>
    <p>{`Currently, a lot of companies adopt the terms of EXW and CNF as the most commonly used terms for international dealings. NexPCB commonly accept deals by adopting EXW, FCA, FOB, CFR, and CIF.
As long as all legal conditions are satisfied, the international trade can be carried out according to any negotiated term, and there will be no disputes under normal circumstances.`}</p>
    <p>{`For more information, visit `}<a parentName="p" {...{
        "href": "https://iccwbo.org/resources-for-business/incoterms-rules/incoterms-rules-2010/"
      }}>{`https://iccwbo.org/resources-for-business/incoterms-rules/incoterms-rules-2010/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      